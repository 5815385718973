import { guid } from '@inwink/utils/methods/guid';
import type { ActionQueueMessage } from '@inwink/actionqueue';
import { Entities } from '@inwink/entities/entities';
import { States } from '@@services/services';
import { eventRequestManagerActions } from '@@event/services/eventactions/requestmgr';
import { eventUserModule } from '@@routes/appmodules';
import * as Discussions from '../../data/discussions';
import {
    addDiscussionThreadMessage,
    setDiscussionThreadLastRead,
    createDiscussionThread as _createDiscussionThread,
    acceptContactInfoRequest as _acceptContactInfoRequest,
    declineContactInfoRequest as _declineContactInfoRequest
} from '../../api/discussionthread';
import {
    acceptMeetingInvitation as _acceptMeetingInvitation,
    declineMeetingInvitation as _declineMeetingInvitation,
    acceptMeetingRequest as _acceptMeetingRequest,
    declineMeetingRequest as _declineMeetingRequest
} from '../../api/meetings';
import { trackEventError } from '@@event/api/tracking';

export const handlers: any = {};

interface ISetLastReadMessage {
    userId: string;
    eventId: string;
    threadId: string;
    lastReadDate: string | Date;
}

export function setLastRead(dispatch, getState: () => States.IAppState,
    discussionThreadId: string): Promise<ActionQueueMessage<ISetLastReadMessage>> {
    const state = getState();
    const user = state.user.currentUser;
    const payload = <ISetLastReadMessage>{
        userId: user.detail.id,
        eventId: state.event.eventid,
        threadId: discussionThreadId,
        lastReadDate: new Date()
    };

    Discussions.setLastRead(user.data, discussionThreadId, payload.lastReadDate);
    return user.data.save().then(() => {
        return {
            type: "threadsetlastread",
            payload: payload
        };
    });
}

handlers.threadsetlastread = (message: ActionQueueMessage<ISetLastReadMessage>,
    context: Actions.ActionQueueProcessContext, dispatch, getState: () => States.IAppState) => {
    return eventRequestManagerActions
        .getEventRequestManager(message.payload.eventId)(dispatch, getState).then((requestmanager) => {
            return setDiscussionThreadLastRead(requestmanager, message.payload.threadId, message.payload.lastReadDate);
        });
};

// ------------------------ Creation d'un discussion thread

interface ICreateDiscussionThread {
    tempId: string;
    userId: string;
    eventId: string;
    exhibitorId?: string;
    personId?: string;
    meetingId?: string;
    withOrganizer?: boolean;
}

export function createDiscussionThread(
    dispatch,
    getState: () => States.IAppState,
    discussionThreadId: string,
    direct?: boolean
): Promise<ActionQueueMessage<ICreateDiscussionThread>> {
    const state = getState();
    const user = state.user.currentUser;
    const msg: Entities.IDiscussionThreadWrapper = user.data.discussionThreads.data.find((d) => d.id === discussionThreadId);

    const exhibitor = msg.thread.participants.filter((p) => p.exhibitor)[0];
    const person = msg.thread.participants.filter((p) => p.person && p.person.id !== user.detail.id)[0];
    const organizer = msg.thread.participants.filter((p) => p.isOrganizer)[0];
    const meeting = msg.thread.meetingId;

    const payload = <ICreateDiscussionThread>{
        tempId: msg.id,
        userId: user.detail.id,
        eventId: state.event.eventid,
        exhibitorId: (!meeting && exhibitor?.exhibitor?.id) || null,
        personId: (!meeting && person?.person?.id) || null,
        meetingId: meeting,
        withOrganizer: !!organizer
    };

    const message = {
        type: "creatediscussionthread",
        payload: payload,
        correlationid: "discussion-" + discussionThreadId
    };

    if (direct) {
        return funcCreateDiscussionThread(message, null, dispatch, getState);
    }

    return Promise.resolve(message);
}

function funcCreateDiscussionThread(
    message: ActionQueueMessage<ICreateDiscussionThread>,
    context: Actions.ActionQueueProcessContext,
    dispatch, getState: () => States.IAppState
) {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(dispatch, getState)
        .then((requestmanager) => {
            let processed = false;
            return _createDiscussionThread(
                requestmanager,
                message.payload.personId,
                message.payload.exhibitorId,
                message.payload.meetingId,
                message.payload.withOrganizer
            ).then((threadid) => {
                processed = true;
                const state = getState();
                if (state.user.currentUser && state.user.currentUser.data && state.user.currentUser.data.discussionThreads) {
                    const thread = state.user.currentUser.data.discussionThreads
                        .data.find((d) => d.id === message.payload.tempId);

                    if (thread) {
                        state.user.currentUser.data.discussionThreads.removeWhere((t) => t.id === message.payload.tempId);
                        thread.id = threadid;
                        thread.thread.id = threadid;
                        delete (thread as any).$transient;
                        delete (thread as any).$loki;
                        delete (thread as any).$meta;

                        const existingThread = state.user.currentUser.data.discussionThreads.data.find((t) => t.id === thread.id);
                        if (!existingThread) {
                            state.user.currentUser.data.discussionThreads.insert(thread);
                        }
                        return state.user.currentUser.data.save().then(() => {
                            return Discussions.getDiscussionMessages(
                                message.payload.userId,
                                message.payload.eventId,
                                message.payload.tempId
                            ).then((tempDiscussion) => {
                                if (tempDiscussion.messages.data.length) {
                                    // si il y avait des messages dans le thread tmp, on les bascule sur le nouveau thread
                                    return Discussions.getDiscussionMessages(message.payload.userId,
                                        message.payload.eventId, threadid).then((discussion) => {
                                        tempDiscussion.messages.data.forEach((discussMessage) => {
                                            const discussionMessage = discussMessage;
                                            discussionMessage.discussionThreadId = threadid;
                                            delete discussionMessage.$transient;
                                            delete (discussionMessage as any).$loki;
                                            delete (discussionMessage as any).meta;
                                            discussion.messages.insert(discussionMessage);
                                        });

                                        return discussion.save().then(() => {
                                            return message;
                                        });
                                    });
                                }
                            });
                        });
                    }
                }
            }).then(null, (err) => {
                if (!processed) {
                    return Promise.reject(err);
                }

                const state: States.IAppState = getState();
                trackEventError(
                    state.event.requestManagers.apiFront,
                    state.event.eventid,
                    err,
                    "error during discussion messages sync"
                );
            });
        });
}

handlers.creatediscussionthread = (message: ActionQueueMessage<ICreateDiscussionThread>,
    context: Actions.ActionQueueProcessContext, dispatch, getState: () => States.IAppState) => {
    return funcCreateDiscussionThread(message, context, dispatch, getState);
};

// ------------------------ Envoi d'un message

interface ISendDiscussionThreadMessage {
    tempId: string;
    userId: string;
    eventId: string;
    threadId: string;
    text: string;
    date: string | Date;
    fields: string;
    isFromActiveChat: boolean;
}

export function sendMessage(
    dispatch,
    getState: () => States.IAppState,
    discussionThreadId: string,
    text: string,
    fields: string,
    isFromActiveChat: boolean
): Promise<ActionQueueMessage<ISendDiscussionThreadMessage>> {
    const state = getState();
    const user = state.user.currentUser;
    const payload = <ISendDiscussionThreadMessage>{
        tempId: guid(),
        userId: user.detail.id,
        eventId: state.event.eventid,
        threadId: discussionThreadId,
        text: text,
        date: new Date(),
        isFromActiveChat,
        fields: fields
    };

    const thread = user.data.discussionThreads.data
        .filter((d) => d.id === discussionThreadId || d.$tempId === discussionThreadId)[0];

    const msg = {
        id: payload.tempId,
        $transient: new Date(),
        $tempId: payload.tempId,
        discussionThreadId: payload.threadId,
        person: {
            id: user.detail.id,
            firstname: user.detail.firstname,
            lastname: user.detail.lastname,
            photo: null
        },
        userMessageDate: payload.date,
        messageDate: payload.date,
        text: payload.text,
        extendedFields: fields
    } as Entities.IDiscussionThreadMessage;

    Discussions.setLastMessage(user.data, discussionThreadId, msg, false);
    return user.data.save().then(() => {
        return Discussions.getDiscussionMessages(
            state.user.currentUser.detail.id,
            state.event.eventid,
            discussionThreadId
        ).then((discussion) => {
            discussion.messages.insert(msg);
            return discussion.save();
        });
    }).then(() => {
        return {
            type: "senddiscussionthreadmessage",
            payload: payload,
            correlationid: "discussion-" + thread.id
        };
    });
}

handlers.senddiscussionthreadmessage = (
    message: ActionQueueMessage<ISendDiscussionThreadMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(
        message.payload.eventId
    )(dispatch, getState).then((requestmanager) => {
        const state = getState();
        const currentdiscussion = state.user && state.user.currentUser
            && state.user.currentUser.data.discussionThreads.data.filter((d) => d.id === message.payload.threadId
            || d.$tempId === message.payload.threadId)[0];

        if (currentdiscussion) {
            if (currentdiscussion.id === currentdiscussion.$tempId) {
                // la discussion n'a pas encore été traité, du coup on ne peut pas encore envoyer le message;
                return Promise.reject(new Error("Discussion was not already create"));
            }
            return addDiscussionThreadMessage(
                requestmanager,
                currentdiscussion.id,
                message.payload.text,
                message.payload.date,
                "trad:discussionthreads.msg.timeslotnotavailable",
                message.payload.isFromActiveChat,
                message.payload.fields
            ).then((msg) => {
                return Discussions.getDiscussionMessages(
                    message.payload.userId,
                    message.payload.eventId,
                    currentdiscussion.id
                ).then((discussion) => {
                    const discussionMessage = discussion.messages.data.find((m) => m.id === message.payload.tempId);
                    if (discussionMessage) {
                        discussion.messages.removeWhere((msgToCheck) => msgToCheck.id === message.payload.tempId);
                        discussionMessage.id = msg.id;
                        delete discussionMessage.$transient;
                        delete (discussionMessage as any).$loki;
                        delete (discussionMessage as any).$meta;

                        discussion.messages.insert(discussionMessage);
                        context.refreshUserData = true;
                        return discussion.save();
                    }
                });
            });
        }
    });
};

// ------------------------ Ajout d'une demande de RDV

// interface IAddMeetingRequestMessage {

// }
// export function addMeetingRequest(
//     dispatch,
//     getState: () => States.IAppState,
//     discussionThreadId: string,
//     text: string
// ): Promise<ActionQueueMessage<IAddMeetingRequestMessage>> {
//     return Promise.resolve({
//         type: "addmeetingrequest",
//         payload: null
//     });
// }

// handlers.addmeetingrequest = (
//     message: ActionQueueMessage<IAddMeetingRequestMessage>,
//     context: Actions.ActionQueueProcessContext,
//     dispatch: () => void,
//     getState: () => States.IAppState
// ) => {
//     return Promise.resolve();
// };

// ------------------------ Validation d'une demande de RDV

interface IAcceptMeetingRequestMessage {
    userId: string;
    eventId: string;
    threadId: string;
    messageId: string;
    requestedMeetingId: string;
    startDate: string | Date;
    endDate: string | Date;
    capacity: number;
    text: string;
    timeSlotNotAvailableText: string;
    location: string;
    meetingKind: string;
    exhibitorAccountId: string;
}

export function requestMeeting(i18nHelper: Entities.i18nHelper, dispatch, getState, discussion): Promise<any> {
    return eventUserModule().then((mod) => mod.networkingModule(dispatch, getState)).then((mod) => {
        return mod.networkingActions.showMeetingRequest(
            i18nHelper, discussion
        )(dispatch, getState).then((request) => {
            if (request) {
                const payload = {
                    type: 'meetingrequest',
                    payload: {
                        exhibitorAccountId: request.exhibitorAccountId,
                        meetingKind: request.meetingKind,
                        title: request.title,
                        description: request.message,
                        location: request.location,
                        capacity: request.capacity,
                        isOnline: request.isOnline,
                        timeslots: request.timeslots
                    }
                };

                const fields = JSON.stringify(payload);
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                return eventUserModule().then((eumod) => eumod.networkingModule(dispatch, getState)).then((netwmod) => {
                    netwmod.discussionThreadActions.sendMessage(
                        discussion.id,
                        request.message,
                        fields
                    )(dispatch, getState);
                }).then((msgid) => msgid)
                    .catch((err) => console.error(err));
            }

            return null;
        });
    });
}

export function acceptMeetingRequest(
    dispatch,
    getState: () => States.IAppState,
    discussionThreadId: string,
    messageId: string,
    requestedMeetingId: string,
    text: string,
    timeSlotNotAvailableText: string,
    startdate: string | Date,
    enddate: string | Date,
    capacity: number,
    location: string,
    meetingKind: string,
    exhibitorAccountId: string
): Promise<ActionQueueMessage<IAcceptMeetingRequestMessage>> {
    const state = getState();
    const payload = <IAcceptMeetingRequestMessage>{
        userId: state.user.currentUser.detail.id,
        eventId: state.event.eventid,
        threadId: discussionThreadId,
        messageId: messageId,
        requestedMeetingId: requestedMeetingId,
        startDate: startdate,
        endDate: enddate,
        capacity: capacity,
        text: text,
        timeSlotNotAvailableText: timeSlotNotAvailableText,
        location: location,
        meetingKind: meetingKind,
        exhibitorAccountId: exhibitorAccountId
    };

    if (requestedMeetingId) {
        const meeting = state.user.currentUser.data.meetings.data.filter((m) => m.id === requestedMeetingId)[0];
        if (meeting) {
            meeting.status = "Accepted";
            const me = meeting.meetingPersons.filter((mp) => mp.person.id === state.user.currentUser.detail.id)[0];
            if (me) {
                me.status = "Accepted";
            }
            state.user.currentUser.data.meetings.update(meeting);
            state.user.currentUser.data.save();
        }
    }

    return Discussions.getDiscussionMessages(
        payload.userId,
        payload.eventId,
        payload.threadId
    ).then((discussion) => {
        const discussionMessage = discussion.messages.data.find((m) => m.id === payload.messageId);
        if (discussionMessage && discussionMessage.extendedFields) {
            const extendedMessage = JSON.parse(discussionMessage.extendedFields);
            extendedMessage.payload.status = 1;
            discussionMessage.extendedFields = JSON.stringify(extendedMessage);
            discussion.messages.update(discussionMessage);
            return discussion.save();
        }
    }).then(() => {
        return {
            type: "acceptmeetingrequest",
            payload: payload
        };
    });
}

handlers.acceptmeetingrequest = (
    message: ActionQueueMessage<IAcceptMeetingRequestMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return _acceptMeetingRequest(
            requestmanager,
            message.payload.requestedMeetingId,
            message.payload
        ).then(() => {
            context.syncUser = true;
            context.syncMeetings = true;
        });
    });
};

// ------------------------ Refus d'une demande de RDV

interface IDeclineMeetingRequestMessage {
    userId: string;
    eventId: string;
    threadId: string;
    messageId: string;
    requestedMeetingId: string;
    text: string
}

export function declineMeetingRequest(
    dispatch,
    getState: () => States.IAppState,
    discussionThreadId: string,
    messageId: string,
    requestedMeetingId: string,
    text: string
): Promise<ActionQueueMessage<IDeclineMeetingRequestMessage>> {
    const state = getState();
    const payload = <IDeclineMeetingRequestMessage>{
        userId: state.user.currentUser.detail.id,
        eventId: state.event.eventid,
        requestedMeetingId: requestedMeetingId,
        threadId: discussionThreadId,
        messageId: messageId,
        text: text
    };

    if (requestedMeetingId) {
        const meeting = state.user.currentUser.data.meetings.data.filter((m) => m.id === requestedMeetingId)[0];
        if (meeting) {
            meeting.status = "Declined";
            const me = meeting.meetingPersons.filter((mp) => mp.person.id === state.user.currentUser.detail.id)[0];
            if (me) {
                me.status = "Declined";
            }
            state.user.currentUser.data.meetings.update(meeting);
            state.user.currentUser.data.save();
        }
    }

    return Discussions.getDiscussionMessages(payload.userId, payload.eventId, payload.threadId).then((discussion) => {
        const discussionMessage = discussion.messages.data.find((m) => m.id === payload.messageId);
        if (discussionMessage && discussionMessage.extendedFields) {
            const extendedMessage = JSON.parse(discussionMessage.extendedFields);
            extendedMessage.payload.status = 1;
            discussionMessage.extendedFields = JSON.stringify(extendedMessage);
            discussion.messages.update(discussionMessage);
            return discussion.save();
        }
    }).then(() => {
        return {
            type: "declinemeetingrequest",
            payload: payload
        };
    });
}

handlers.declinemeetingrequest = (
    message: ActionQueueMessage<IDeclineMeetingRequestMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return _declineMeetingRequest(
            requestmanager,
            message.payload.requestedMeetingId,
            // message.payload.threadId,
            // message.payload.messageId,
            message.payload.text
        ).then(() => {
            context.syncUser = true;
            context.syncMeetings = true;
        });
    });
};

// ------------------------ Validation d'une demande de RDV

interface IAcceptMeetingInvitationMessage {
    userId: string;
    eventId: string;
    threadId: string;
    messageId: string;
    text: string;
    meetingId: string;
    exhibitorAccountId: string;
}

export function acceptMeetingInvitation(
    dispatch,
    getState: () => States.IAppState,
    discussionThreadId: string,
    messageId: string,
    text: string,
    meetingId: string,
    exhibitorAccountId: string
): Promise<ActionQueueMessage<IAcceptMeetingInvitationMessage>> {
    const state = getState();
    const payload = <IAcceptMeetingInvitationMessage>{
        userId: state.user.currentUser.detail.id,
        eventId: state.event.eventid,
        threadId: discussionThreadId,
        messageId: messageId,
        text: text,
        meetingId: meetingId,
        exhibitorAccountId: exhibitorAccountId
    };

    return Discussions.getDiscussionMessages(payload.userId, payload.eventId, payload.threadId).then((discussion) => {
        const discussionMessage = discussion.messages.data.find((m) => m.id === payload.messageId);
        if (discussionMessage && discussionMessage.extendedFields) {
            const extendedMessage = JSON.parse(discussionMessage.extendedFields);
            extendedMessage.payload.status = 1;
            discussionMessage.extendedFields = JSON.stringify(extendedMessage);
            discussion.messages.update(discussionMessage);
            return discussion.save();
        }
    }).then(() => {
        return {
            type: "acceptmeetinginvitation",
            payload: payload
        };
    });
}

handlers.acceptmeetinginvitation = (
    message: ActionQueueMessage<IAcceptMeetingInvitationMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return _acceptMeetingInvitation(
            requestmanager,
            message.payload.messageId,
            message.payload.text,
            message.payload.meetingId,
            message.payload.exhibitorAccountId
        ).then(() => {
            context.syncUser = true;
            context.syncMeetings = true;
        });
    });
};

// ------------------------ Refus d'une demande de RDV

interface IDeclineMeetingInvitationMessage {
    userId: string;
    eventId: string;
    threadId: string;
    messageId: string;
    meetingId: string;
    text: string
}

export function declineMeetingInvitation(
    dispatch,
    getState: () => States.IAppState,
    discussionThreadId: string,
    messageId: string,
    text: string,
    meetingId: string
): Promise<ActionQueueMessage<IDeclineMeetingInvitationMessage>> {
    const state = getState();
    const payload = <IDeclineMeetingInvitationMessage>{
        userId: state.user.currentUser.detail.id,
        eventId: state.event.eventid,
        threadId: discussionThreadId,
        messageId: messageId,
        meetingId: meetingId,
        text: text
    };

    return Discussions.getDiscussionMessages(payload.userId, payload.eventId, payload.threadId).then((discussion) => {
        const discussionMessage = discussion.messages.data.find((m) => m.id === payload.messageId);
        if (discussionMessage && discussionMessage.extendedFields) {
            const extendedMessage = JSON.parse(discussionMessage.extendedFields);
            extendedMessage.payload.status = 1;
            discussionMessage.extendedFields = JSON.stringify(extendedMessage);
            discussion.messages.update(discussionMessage);
            return discussion.save();
        }
    }).then(() => {
        return {
            type: "declinemeetinginvitation",
            payload: payload
        };
    });
}

handlers.declinemeetinginvitation = (
    message: ActionQueueMessage<IDeclineMeetingInvitationMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return _declineMeetingInvitation(
            requestmanager,
            // message.payload.threadId,
            message.payload.messageId,
            message.payload.text,
            message.payload.meetingId
        ).then(() => {
            context.syncUser = true;
        });
    });
};

// ------------------------ Validation d'une demande de partage de contact info

interface IAcceptContactInfoRequestMessage {
    userId: string;
    eventId: string;
    threadId: string;
    messageId: string;
    text: string;
    date: Date;
}

export function acceptContactInfoRequest(
    dispatch,
    getState: () => States.IAppState,
    discussionThreadId: string,
    messageId: string,
    text: string
): Promise<ActionQueueMessage<IAcceptContactInfoRequestMessage>> {
    const state = getState();
    const payload = <IAcceptContactInfoRequestMessage>{
        userId: state.user.currentUser.detail.id,
        eventId: state.event.eventid,
        threadId: discussionThreadId,
        messageId: messageId,
        text: text,
        date: new Date()
    };

    return Discussions.getDiscussionMessages(payload.userId, payload.eventId, payload.threadId).then((discussion) => {
        const discussionMessage = discussion.messages.data.find((m) => m.id === payload.messageId);
        if (discussionMessage && discussionMessage.extendedFields) {
            const extendedMessage = JSON.parse(discussionMessage.extendedFields);
            extendedMessage.payload.status = 1;
            discussionMessage.extendedFields = JSON.stringify(extendedMessage);
            discussion.messages.update(discussionMessage);
            return discussion.save();
        }
    }).then(() => {
        return {
            type: "acceptcontactinforequest",
            payload: payload
        };
    });
}

handlers.acceptcontactinforequest = (
    message: ActionQueueMessage<IAcceptContactInfoRequestMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return _acceptContactInfoRequest(
            requestmanager,
            message.payload.threadId,
            message.payload.messageId,
            message.payload.text,
            message.payload.date
        ).then(() => {
            context.syncUser = true;
            context.syncMeetings = true;
        });
    });
};

// ------------------------ Refus d'une demande de partage de contact info

interface IDeclineContactInfoRequestMessage {
    userId: string;
    eventId: string;
    threadId: string;
    messageId: string;
    text: string;
    date: Date;
}

export function declineContactInfoRequest(
    dispatch,
    getState: () => States.IAppState,
    discussionThreadId: string,
    messageId: string,
    text: string
): Promise<ActionQueueMessage<IDeclineContactInfoRequestMessage>> {
    const state = getState();
    const payload = <IDeclineContactInfoRequestMessage>{
        userId: state.user.currentUser.detail.id,
        eventId: state.event.eventid,
        threadId: discussionThreadId,
        messageId: messageId,
        text: text,
        date: new Date()
    };

    return Discussions.getDiscussionMessages(payload.userId, payload.eventId, payload.threadId).then((discussion) => {
        const discussionMessage = discussion.messages.data.find((m) => m.id === payload.messageId);
        if (discussionMessage && discussionMessage.extendedFields) {
            const extendedMessage = JSON.parse(discussionMessage.extendedFields);
            extendedMessage.payload.status = 1;
            discussionMessage.extendedFields = JSON.stringify(extendedMessage);
            discussion.messages.update(discussionMessage);
            return discussion.save();
        }
    }).then(() => {
        return {
            type: "declinecontactinforequest",
            payload: payload
        };
    });
}

handlers.declinecontactinforequest = (
    message: ActionQueueMessage<IDeclineContactInfoRequestMessage>,
    context: Actions.ActionQueueProcessContext,
    dispatch: () => void,
    getState: () => States.IAppState
) => {
    return eventRequestManagerActions.getEventRequestManager(message.payload.eventId)(
        dispatch,
        getState
    ).then((requestmanager) => {
        return _declineContactInfoRequest(
            requestmanager,
            message.payload.threadId,
            message.payload.messageId,
            message.payload.text,
            message.payload.date
        ).then(() => {
            context.syncUser = true;
        });
    });
};
